<template>
  <v-col class="pa-3">
    <v-form ref="form" v-model="isValid" @submit.prevent="handleRegister" class="pt-10">
      <h1 class="text-h1 mt-10">
        {{ $t('authorisation.passwordForgotten.title') }}
      </h1>
      <v-alert :type="alertType" :value="!!alertMessage.length" class="my-5" transition="fade-transition">
        {{ alertMessage }}
      </v-alert>
      <k-field-group language-prefix="authorisation.fields" class="mt-4 mb-2">
        <KTextField v-model="form.email"
                    autocomplete="username"
                    autofocus
                    field="email"
                    tabindex="1"/>
      </k-field-group>
      <v-row class="mx-0 my-6" justify="end">
        <VBtn :to="{name:'login'}"
              class="mr-3"
              text tile
              tabindex="3"
              v-text="$t('actions.back')"/>
        <VBtn :loading="isLoading"
              tile
              depressed
              color="secondary"
              tabindex="2"
              type="submit"
              v-text="$t('authorisation.passwordForgotten.request')"/>
      </v-row>
    </v-form>
  </v-col>
</template>

<script>
import KFieldGroup from '@/components/crud/fields/KFieldGroup.vue';
import KTextField from '@/components/crud/fields/KTextField.vue';
import ForgottenRequest from '@/modules/authorisation/api/forgotten.js';

export default {
  name: 'PasswordForgotten',
  components: {
    KFieldGroup,
    KTextField,
  },
  data() {
    return {
      alertType: 'success',
      alertMessage: '',
      isLoading: false,
      isValid: false,
      form: {
        email: '',
      },
    };
  },
  methods: {
    handleRegister() {
      this.$refs.form.validate();

      if (!this.isValid) return;

      this.isLoading = true;
      this.alertMessage = '';
      this.alertType = 'error';

      ForgottenRequest(this.form.email)
          .then(() => {
            this.alertMessage = this.$t('authorisation.passwordForgotten.successMessage');
            this.alertType = 'success';
          })
          .catch(() => {
            this.alertMessage = this.$t('errors.422');
            this.$refs.form.validate();
          })
          .finally(() => {
            this.isLoading = false;
          });
    },
  },
};
</script>
